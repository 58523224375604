export const GLOBAL_CSS_DATA_ATTR = "data-mvt-global-css"

// Must match /app-proxy-v2/src/constants.js
const GLOBAL_HEAD_CODE_DATA_ATTR = "global-head-added"
const GLOBAL_BODY_CODE_DATA_ATTR = "global-body-added"
const GLOBAL_HEAD_CODE_ADDED_SELECTOR = `data-mvt="${GLOBAL_HEAD_CODE_DATA_ATTR}"`
const GLOBAL_BODY_CODE_ADDED_SELECTOR = `data-mvt="${GLOBAL_BODY_CODE_DATA_ATTR}"`

export const addGlobalCSS = (css: string) => {
  if (document.querySelector(`[${GLOBAL_CSS_DATA_ATTR}]`)) {
    return
  }

  const style = document.createElement("style")
  style.textContent = css
  style.setAttribute(GLOBAL_CSS_DATA_ATTR, "true")
  document.head.appendChild(style)
}

export const addGlobalCode = (html: string, head = true) => {
  const selector = head
    ? GLOBAL_HEAD_CODE_ADDED_SELECTOR
    : GLOBAL_BODY_CODE_ADDED_SELECTOR

  if (document.querySelector(`[${selector}]`)) {
    // Return if the worker has already added the code server side
    return
  }

  const container = document.createElement("div")
  container.innerHTML = html

  const appendTo = head ? document.head : document.body

  Array.from(container.children).forEach(node => {
    if (node.tagName === "SCRIPT") {
      const script = document.createElement("script")

      Array.from(node.attributes).forEach(attr =>
        script.setAttribute(attr.name, attr.value)
      )

      script.textContent = node.textContent
      appendTo.appendChild(script)
    } else {
      appendTo.appendChild(node)
    }
  })

  // Add element to ensure we don't add the custom code again
  const el = head
    ? document.createElement("meta")
    : document.createElement("div")

  const dataAttr = head
    ? GLOBAL_HEAD_CODE_DATA_ATTR
    : GLOBAL_BODY_CODE_DATA_ATTR

  el.setAttribute("data-mvt", dataAttr)
  appendTo.appendChild(el)
}
