import Vue from "vue"
import VueOnsen from "vue-onsenui/esm"

VueOnsen.platform.select("android")

Vue.use(VueOnsen)

const components = ["VOnsToast"]

components.forEach(component => {
  import(`vue-onsenui/esm/components/${component}`).then(module => {
    Vue.component(component, module.default)
  })
})

export default VueOnsen
