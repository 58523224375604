import { isObject } from "@/lib/utils"

// Used when localStorage is not available
const MEMORY_LOCAL_STORE = {
  store: {},
  setItem: function(key, value) {
    this.store[key] = value
    return true
  },
  getItem: function(key) {
    return this.store[key]
  },
  removeItem: function(key) {
    delete this.store[key]
    return true
  }
}

export const LS_KEYS = {
  DOWNLOAD_PROMPTED: "fit-download-prompted",
  ASKED_PUSH_PERMISSION: "fit-asked-push-permission",
  WORKOUT_MODE_PREFERENCE: "fit-workout-mode",
  WORKOUTS_MUTED: "fit-workouts-muted",
  PAGE_CACHE: "fit-pc:",
  LAST_PUSH_ENABLED: "fit-last-push-enabled",
  LAST_EMAIL: "mvt-last-email",
  GLOBAL_STATE: "mvt-global-state"
}

// TODO: Update to same API as localStorage
// .setItem
export const set = (key, value) => {
  const val = isObject(value) ? JSON.stringify(value) : value

  try {
    return window.localStorage.setItem(key, val)
  } catch (e) {
    return MEMORY_LOCAL_STORE.setItem(key, val)
  }
}

// TODO: Update to same API as localStorage
// .getItem
export const get = (key, forceParse = false) => {
  let val

  try {
    val = window.localStorage.getItem(key)
  } catch (e) {
    val = MEMORY_LOCAL_STORE.getItem(key)
  }

  return val && ((val.startsWith("{") && val.endsWith("}")) || forceParse)
    ? JSON.parse(val)
    : val
}

// TODO: Update to same API as localStorage
// .removeItem
export const unset = key => {
  try {
    return window.localStorage.removeItem(key)
  } catch (e) {
    return MEMORY_LOCAL_STORE.removeItem(key)
  }
}
