import { AppGlobalState } from "@shared/types"

declare global {
  interface Window {
    FIT_globalState?: AppGlobalState
    FIT_isAppStore?: boolean
  }
}

export const VARIABLES = {
  GLOBAL_STATE: "FIT_globalState",
  IS_APP_STORE: "FIT_isAppStore",
  STRIPE: "FIT_Stripe"
} as const

// Selected with [data-mvt="$selector"]
export const MVT_SELECTORS = {
  MY_CONTENT: "my-content",
  MY_CONTENT_MESSAGES: "my-content-messages",
  MY_CONTENT_SCHEDULE: "my-content-schedule",
  MY_CONTENT_SUBMISSIONS: "my-content-submissions",

  ACCOUNT: "account",
  ACCOUNT_DETAILS: "account-details",
  ACCOUNT_SETTINGS: "account-settings",
  ACCOUNT_PURCHASES: "account-purchases",
  ACCOUNT_SUBSCRIPTIONS: "account-subscriptions",
  ACCOUNT_NOTIFICATIONS: "account-notifications",
  ACCOUNT_SUPPORT: "account-support",
  ACCOUNT_HELP: "account-help",
  ACCOUNT_PRIVACY: "account-privacy",
  ACCOUNT_LOGOUT: "account-logout",

  MESSAGES_SEARCH: "messages-search",
  MESSAGES_TABS: "messages-tabs",
  MESSAGES_THREADS: "messages-threads",

  THEME: "theme"
}

export const MVT_EVENTS = {
  ON_PAGE_CHANGE: "onPageChange",
  ON_PAGE_INITIAL_LOAD: "onPageInitialLoad"
}

export const isAppStore = (): boolean => !!window[VARIABLES.IS_APP_STORE]
