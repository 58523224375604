import Vue from "vue"
import store from "@/store"
import { standardizeUrl } from "@/lib/url-helpers"

export const trackGTMEvent = (event: string, data: object): void => {
  const gtm = (Vue as any).gtm

  if (!gtm || !gtm.enabled()) return

  let trackedData = data

  if (!("user" in data)) {
    const currentUser = store.getters["auth/currentUser"]

    trackedData = {
      ...trackedData,
      user: currentUser?.subscriberUuid
        ? { id: currentUser?.subscriberUuid }
        : null
    }
  }

  gtm.trackEvent({
    event,
    ...trackedData
  })
}

export const trackGTMLinkClick = (url: string, text: string): void => {
  if (!url) return

  const data = {
    data: {
      text: text?.replace(/\n/g, " ").trim(),
      href: standardizeUrl(url)
    }
  }

  trackGTMEvent(GTM_EVENTS.LINK_CLICK, data)

  if (isTrackedDownloadLink(url)) {
    trackGTMEvent(GTM_EVENTS.FILE_DOWNLOAD, data)
  } else if (url.startsWith("mailto:")) {
    trackGTMEvent(GTM_EVENTS.EMAIL_CLICK, data)
  } else if (url.startsWith("tel:")) {
    trackGTMEvent(GTM_EVENTS.TELEPHONE_CLICK, data)
  }
}

export const isTrackedDownloadLink = (url: string): boolean =>
  /\.(pdf|docx?|xlsx?|zip|rar|csv|pptx?)$/i.test(url)

export const GTM_EVENTS = {
  PAGE_VIEW: "custom_page_view",
  LOGIN: "login",
  SIGN_UP: "sign_up",
  FORM_SUBMIT: "form_submit",
  SEARCH: "search",
  BUTTON_CLICK: "button_click",
  CONTENT_ERROR: "content_error",
  NAVIGATION_ITEM_CLICK: "navigation_item_click",
  LINK_CLICK: "link_click",
  FEED_JOIN: "feed_join",
  FILE_DOWNLOAD: "file_download",
  TELEPHONE_CLICK: "telephone_click",
  EMAIL_CLICK: "email_click",
  VIDEO_START: "video_start",
  VIDEO_WATCH: "video_watch",
  SOCIAL_CLICK: "social_click"
} as const
