import scssVariables from "@/assets/styles/variables.module.scss"
import BRAND_COLORS from "@shared/brand-colors.json"

import determineTextColor from "@/lib/determine-text-color"
import { embedFont, SYSTEM_FONT_STACK } from "@/lib/fonts"
import { MVT_SELECTORS } from "@/lib/globals"

class Theme {
  constructor() {
    this.options = {
      "--button-text-color-hex": "#fff",
      "--border-radius": "6px",
      "--input-border-radius": "12px",
      "--card-shadow": scssVariables["box-shadow--small"]
    }

    Object.values(BRAND_COLORS).forEach(color => {
      this.options[color.hex_css_val] = color.hex_default
      this.options[color.rgb_css_val] = color.rgb_default
      this.options[color.text_hex_css_val] = determineTextColor(
        color.hex_default
      )
    })
  }

  set(attribute, value) {
    this.options[attribute] = value
  }

  insert() {
    const existingTheme = document.querySelector(
      `[data-mvt="${MVT_SELECTORS.THEME}"]`
    )

    if (existingTheme) {
      existingTheme.innerHTML = this.toString()
    } else {
      const el = document.createElement("style")
      el.type = "text/css"
      el.dataset.mvt = MVT_SELECTORS.THEME

      el.appendChild(document.createTextNode(this.toString()))
      document.head.appendChild(el)
    }
  }

  toString() {
    let css = ":root {\n"

    Object.keys(this.options).forEach(key => {
      css += `${key}: ${this.options[key]};\n`
    })

    css += "}"

    return css
  }

  rbgToVals(rgb) {
    return rgb.replace(/rgb\(/, "").replace(")", "")
  }
}

export default themeParams => {
  const theme = new Theme()
  const {
    app_look,
    card_style,
    default_color_mode,
    heading_font,
    body_font
  } = themeParams

  if (app_look) {
    switch (app_look) {
      case "rounded":
        theme.set("--input-border-radius", "24px")
        theme.set("--border-radius", "16px")
        break
      case "square":
        theme.set("--input-border-radius", "3px")
        theme.set("--border-radius", "3px")
        break
      default:
        break
    }
  }

  if (card_style) {
    switch (card_style) {
      case "raised":
        theme.set("--card-shadow", scssVariables["box-shadow--large"])
        break
      case "flat":
        theme.set("--card-shadow", scssVariables["box-shadow--keyline"])
        break
      default:
        break
    }
  }

  if (body_font) {
    theme.set("--body-font-stack", `"${body_font}"`)
    embedFont(body_font)
  } else {
    theme.set("--body-font-stack", SYSTEM_FONT_STACK)
  }

  if (heading_font) {
    theme.set("--heading-font-stack", `"${heading_font}"`)
    embedFont(heading_font)
  } else {
    theme.set("--heading-font-stack", SYSTEM_FONT_STACK)
  }

  if (themeParams.primary_color) {
    theme.set(
      "--button-text-color-hex",
      determineTextColor(themeParams.primary_color.hex, {
        black: scssVariables["color-ui--grey-80"],
        white: "#fff"
      })
    )
  }

  Object.values(BRAND_COLORS).forEach(color => {
    if (themeParams[color.field]) {
      theme.set(color.hex_css_val, themeParams[color.field].hex)
      theme.set(
        color.rgb_css_val,
        theme.rbgToVals(themeParams[color.field].rgb)
      )
      theme.set(
        color.text_hex_css_val,
        determineTextColor(themeParams[color.field].hex)
      )
    }
  })

  // Preserve legacy brand colors
  if ("brand_color" in themeParams) {
    const rgbVals = themeParams.brand_color.rgb
      .replace(/rgb\(/, "")
      .replace(")", "")

    theme.set("--brand-color-hex", themeParams.brand_color.hex)
    theme.set("--brand-color-rgb-vals", rgbVals)
    theme.set(
      "--button-text-color-hex",
      determineTextColor(themeParams.brand_color.hex, {
        black: scssVariables["color-ui--grey-80"],
        white: "#fff"
      })
    )

    theme.set("--primary-brand-color-hex", themeParams.brand_color.hex)
    theme.set("--primary-brand-color-rgb-vals", rgbVals)
  }

  if (default_color_mode === "dark") {
    document.body.classList.add("--dark-mode")
  } else {
    document.body.classList.remove("--dark-mode")
  }

  theme.insert()
}
