import Vue from "vue"
import joinUrl from "url-join"
import Axios from "axios"

import ApiClient from "@/lib/api-client"
import { isPresent } from "@/lib/utils"

import { findProgressRoot, findAccessRoot } from "@/lib/access-utils"
import { ROOT_TREE_ID } from "@/lib/page-utils"
import {
  getCachedPage,
  cacheAccessTree,
  getCachedAccessTree
} from "@/lib/page-cache"

const LOAD_RETRY_LIMIT = 100 // Max 10 seconds w/ 100ms delay

export default {
  namespaced: true,

  state: {
    currentPage: {},
    pageAttributes: {},
    pageCompletions: {},
    pageSequences: {},
    pageProgress: {},
    pageAccess: {},
    pageTrees: {},
    pageSubscriptions: {}, // Subscriptions from current user
    pageSubscribers: {}, // Count of _other_ subscribers for a page
    pageCanStartViaPack: {},
    bookmarkedPages: {},
    toolbarHiddenPages: {},

    isFetchingPageData: {},
    isFetchingAccessRoot: {},
    hasFetchedAccessRoot: {}
  },

  actions: {
    completePage({ commit }, pageId) {
      return ApiClient.post(`/pages/${pageId}/complete`).then(({ data }) => {
        if (data.root_progress) {
          commit("setPageSubscription", data.root_progress.page_id)

          commit("setPageProgress", {
            pageId: data.root_progress.page_id,
            progress: data.root_progress.progress
          })
        }

        commit("setPageCompletion", {
          pageId: data.page_id,
          complete: true
        })

        return data
      })
    },

    uncompletePage({ commit }, pageId) {
      return ApiClient.delete(`/pages/${pageId}/complete`).then(() => {
        commit("setPageCompletion", {
          pageId,
          complete: false
        })
      })
    },

    subscribe({ commit }, { pageId, startDate, startFromPageId }) {
      return ApiClient.post(`/pages/${pageId}/subscribers`, {
        started_at: startDate,
        start_from_page_id: startFromPageId
      }).then(res => {
        commit("setPageSubscription", res.data.page_id)
        commit("setPageProgress", {
          pageId: res.data.page_id,
          progress: res.data.progress
        })

        commit("setPageSequences", {
          pageId: res.data.page_id,
          data: {
            list: res.data.sequence
          }
        })

        res.data.sequence.forEach(page => {
          commit("setPageCompletion", {
            pageId: page.page_id,
            complete: page.is_completed
          })
        })

        return res.data
      })
    },

    unsubscribe({ dispatch, commit, state }, pageId) {
      return ApiClient.delete(`/pages/${pageId}/subscribers`).then(res => {
        const removedPageIds = Object.values(
          state.pageSequences[pageId]?.list || {}
        ).map(page => page.page_id)

        removedPageIds.forEach(pageId => {
          dispatch(
            "schedule/removeSubscriberSchedulesByItem",
            {
              itemId: pageId,
              itemType: "page"
            },
            {
              root: true
            }
          )
        })

        dispatch("resetPageSequences", pageId)
        commit("deletePageSubscription", pageId)

        return res.data
      })
    },

    fetchProgress({ commit }, pageId) {
      return ApiClient.get(`/pages/${pageId}/complete`).then(res => {
        commit("setPageCompletion", {
          pageId,
          complete: res.data.is_completed
        })
        return res.data
      })
    },

    resetPageSequences({ commit, state }, pageId) {
      const list = (state.pageSequences[pageId]?.list || []).map(s => ({
        ...s,
        is_completed: false
      }))

      commit("setPageSequences", { pageId, data: { list } })
    },

    togglePageBookmark({ commit }, pageId) {
      return ApiClient.post(`/pages/${pageId}/bookmarks/toggle`).then(res => {
        const isBookmarked = res.data.is_bookmarked
        commit("setPageIsBookmarked", { pageId, isBookmarked })

        return isBookmarked
      })
    },

    fetchPageData({ dispatch, commit, state }, pageId) {
      if (state.isFetchingPageData[pageId]) return

      commit("setFetchingPageData", { pageId, fetching: true })

      return dispatch("fetchPageSubscribers", pageId).finally(() => {
        commit("setFetchingPageData", { pageId, fetching: false })
      })
    },

    // TODO: Rename to fetch page data
    fetchPageSubscribers({ commit }, pageId) {
      return ApiClient.get(`/pages/${pageId}/subscribers`).then(res => {
        commit("setPageSubscribers", {
          pageId: res.data.page_id,
          count: res.data.count,
          last_5: res.data.last_5,
          can_schedule_start: res.data.can_schedule_start
        })

        commit("setPageSequences", {
          pageId: res.data.page_id,
          data: {
            homePage: {
              title: res.data.page_title,
              path: res.data.page_path
            },
            list: res.data.sequence
          }
        })

        commit("setPageProgress", {
          pageId: res.data.page_id,
          progress: res.data.progress
        })

        res.data.sequence.forEach(page => {
          commit("setPageCompletion", {
            pageId: page.page_id,
            complete: page.is_completed
          })
        })

        return res.data
      })
    },

    fetchPageTree({ commit }, rootId) {
      const root = rootId ? rootId : ROOT_TREE_ID

      return ApiClient.get("/pages/tree", {
        params: {
          root: root === ROOT_TREE_ID ? null : root
        }
      }).then(res => {
        commit("setPageTree", { root, tree: res.data })

        res.data.forEach(page => {
          commit("setPageCompletion", {
            pageId: page.page_id,
            complete: page.is_completed
          })

          page.pages.forEach(page => {
            commit("setPageCompletion", {
              pageId: page.page_id,
              complete: page.is_completed
            })
          })
        })

        return res.data
      })
    },

    fetchPagePath(_, pageId) {
      return ApiClient.get(`/pages/${pageId}/path`).then(res => {
        return res.data.path
      })
    },

    async loadAccessCacheIntoMemory({ commit }, rootId) {
      const accessTree = await getCachedAccessTree(rootId)

      if (isPresent(accessTree)) {
        Object.keys(accessTree).forEach(pageId => {
          commit("setPageAccess", {
            pageId: pageId,
            access: accessTree[pageId]
          })
        })

        return true
      } else {
        return true
      }
    },

    loadAccess(
      { dispatch, getters, rootGetters, state, commit },
      { pageId, isAwaitingAuthentication = false, retryCount = 0 }
    ) {
      const setFetching = rootId => {
        commit("setIsFetchingAccessRoot", {
          pageId: rootId,
          isFetching: true
        })
      }

      const setResolved = rootId => {
        commit("setHasFetchedAccessRoot", {
          pageId: rootId,
          hasFetched: true
        })
        commit("setIsFetchingAccessRoot", {
          pageId: rootId,
          isFetching: false
        })
      }

      return new Promise(resolve => {
        getCachedPage(pageId).then(async page => {
          if (!page || !page.root_id) return resolve() // Likely Legacy Content

          const rootId = page.root_id

          // If we have attempted to load the access tree too many times, we should just resolve
          if (retryCount >= LOAD_RETRY_LIMIT) {
            setResolved(rootId)

            return resolve()
          }

          if (!isAwaitingAuthentication && state.isFetchingAccessRoot[rootId]) {
            // Early return if there is already a fetch call being made
            // Importantly, we will not resolve early if we are waiting on the auth status to be resolved.
            // If we are waiting on that, we need to instead defer to the `hasCheckedAuthentication` below, which
            // may in turn re-call this method

            return setTimeout(async () => {
              // We will wait for 100ms, and then try again (which should resolve early)
              await dispatch("loadAccess", {
                pageId,
                retryCount: retryCount + 1
              })

              return resolve()
            }, 100)
          }

          if (state.hasFetchedAccessRoot[rootId]) {
            // We can return early if another call has resolved and fetched the data
            return resolve()
          }

          // Ensure other callers know we are currently fetching the access tree
          setFetching(rootId)

          if (!getters.getPageAccess(rootId)) {
            await dispatch("loadAccessCacheIntoMemory", rootId)
          }

          // After loading from the cache, if we still don't have access data, we need to fetch it
          // and we will wait for the resolution
          if (!getters.getPageAccess(rootId)) {
            await dispatch("fetchAccessTree", rootId)
          } else {
            // We still want to get the most up to date data, but we can resolve early with the cached
            // data that is now in memory
            dispatch("fetchAccessTree", rootId)
          }

          const [accessRoot] = findAccessRoot(pageId)

          if (
            !accessRoot ||
            !(accessRoot.is_scheduled || accessRoot.is_progress_root)
          ) {
            // If the page is not drip released or is a progress_root we can safely
            // resolve as we have all the data we need
            setResolved(rootId)

            return resolve()
          }

          // This is the special handling we need to do for Drip
          // We need to make an API call to fetch some data on the progress root (e.g. when it was started)
          // But, we need to ensure the PWA has checked the authentication status before we make the call
          // If we havn't checked the auth status, we need to wait and try again
          const progressRoot = findProgressRoot(pageId)

          if (!progressRoot) {
            // Resolving early here actually introduces a bug when the page previously didn't have
            // an progress root, but now does. Instead, we should actually re-check loadAccess after
            // the fresh data has been resolved by fetchAccessTree
            // Something like:
            //
            // await dispatch("fetchAccessTree", { pageId, isAwaitingFreshData: true })
            //
            setResolved(rootId)

            return resolve()
          }

          if (rootGetters["auth/hasCheckedAuthentication"]) {
            // If the page is part of drip release, we need to fetch the `started_at` date
            // from the server ONLY after we know if the user is logged in or not
            // Calling before `hasCheckedAuthentication` has been set will result in the
            // incorrect `started_at` date being fetched and the page being incorrectly locked
            await dispatch("fetchPageData", progressRoot)

            setResolved(rootId)

            return resolve()
          } else {
            setTimeout(async () => {
              // We will wait for 250ms, and then try again
              // We also tell `loadAccess` that we are waiting on auth data, so that it doesnt
              // return early and instead waits for the `hasCheckedAuthentication` to be set
              await dispatch("loadAccess", {
                pageId,
                isAwaitingAuthentication: true,
                retryCount: retryCount + 1
              })

              return resolve()
            }, 250)
          }
        })
      })
    },

    fetchAccessTree({ commit }, rootId) {
      const url = joinUrl(
        process.env.VUE_APP_APP_DATA_PATH,
        "a",
        `${rootId}.json`,
        `?ts=${new Date().getTime()}`
      )

      return Axios.get(url).then(res => {
        commit("setAccessTree", { tree: res.data, rootId })
      })
    }
  },

  mutations: {
    setFetchingPageData(state, { pageId, isFetching = true }) {
      Vue.set(state.isFetchingPageData, pageId, isFetching)
    },

    setIsFetchingAccessRoot(state, { pageId, isFetching = true }) {
      Vue.set(state.isFetchingAccessRoot, pageId, isFetching)
    },

    setHasFetchedAccessRoot(state, { pageId, hasFetched = true }) {
      Vue.set(state.hasFetchedAccessRoot, pageId, hasFetched)
    },

    setCurrentPage(
      state,
      {
        id,
        rootId,
        contentRootId,
        path,
        actions,
        access,
        type,
        title,
        show_tabbar
      }
    ) {
      state.currentPage = {
        id,
        rootId,
        contentRootId,
        path,
        actions,
        access,
        type,
        title,
        show_tabbar
      }
    },

    setPageAttributes(state, { pageId, attributes }) {
      Vue.set(state.pageAttributes, pageId, attributes)
    },

    setPageCompletion(state, { pageId, complete }) {
      Vue.set(state.pageCompletions, pageId, complete)
    },

    setPageCompletions(state, pageCompletions) {
      state.pageCompletions = pageCompletions
    },

    setPageSubscriptions(state, pageSubscriptions) {
      state.pageSubscriptions = pageSubscriptions
    },

    setPageSubscription(state, pageId) {
      Vue.set(state.pageSubscriptions, pageId, true)
    },

    setPageSubscribers(state, { pageId, count, last_5, can_schedule_start }) {
      Vue.set(state.pageSubscribers, pageId, {
        count,
        last_5,
        can_schedule_start
      })
    },

    setPageSequences(state, { pageId, data }) {
      Vue.set(state.pageSequences, pageId, {
        ...(state.pageSequences[pageId] || {}),
        ...data
      })
    },

    setPageProgress(state, { pageId, progress }) {
      Vue.set(state.pageProgress, pageId, {
        ...(state.pageProgress[pageId] || {}),
        ...progress
      })
    },

    setAccessTree(state, { rootId, tree }) {
      Object.keys(tree).forEach(pageId => {
        Vue.set(state.pageAccess, pageId, tree[pageId])
      })

      cacheAccessTree(rootId, tree)
    },

    setPageAccess(state, { pageId, access }) {
      Vue.set(state.pageAccess, pageId, access)
    },

    setPageCanStartViaPack(state, { pageId, canStartViaPack }) {
      state.pageCanStartViaPack = { [pageId]: canStartViaPack }
    },

    setBookmarkedPages(state, bookmarkedPages) {
      state.bookmarkedPages = bookmarkedPages
    },

    setPageIsBookmarked(state, { pageId, isBookmarked }) {
      Vue.set(state.bookmarkedPages, pageId, isBookmarked)
    },

    deletePageProgress(state, pageId) {
      Vue.delete(state.pageProgress, pageId)
    },

    deletePageSubscription(state, pageId) {
      Vue.delete(state.pageSubscriptions, pageId)
    },

    hidePageToolbar(state, pageId) {
      Vue.set(state.toolbarHiddenPages, pageId, true)
    },

    showPageToolbar(state, pageId) {
      Vue.set(state.toolbarHiddenPages, pageId, false)
    },

    setSubmissionsCount(state, { pageId, count }) {
      Vue.set(state.submissionsCount, pageId, count)
    },

    setPageTree(state, { root, tree }) {
      Vue.set(state.pageTrees, root, tree)
    }
  },

  getters: {
    isPageComplete(state, _, __, rootGetters) {
      return pageId => {
        if (rootGetters["auth/isAuthenticated"]) {
          return !!state.pageCompletions[pageId]
        }

        return false
      }
    },

    getPageSequence(state) {
      return pageId => state.pageSequences[pageId] || {}
    },

    getPageProgress(state) {
      return pageId => state.pageProgress[pageId] || {}
    },

    getPageAccess(state) {
      return page => state.pageAccess[page]
    },

    getCanStartViaPack(state) {
      return pageId => !!state.pageCanStartViaPack[pageId]
    },

    isSubscribedToPage(state) {
      return pageId => !!state.pageSubscriptions[pageId]
    },

    getPageSubscribers(state) {
      return pageId => state.pageSubscribers[pageId]
    },

    isPageBookmarked(state) {
      return pageId => !!state.bookmarkedPages[pageId]
    },

    isToolbarHidden(state) {
      return pageId => !!state.toolbarHiddenPages[pageId]
    },

    submissionsCountByPage(state) {
      return pageId => state.submissionsCount[pageId]
    },

    getPageAttributes(state) {
      return pageId => state.pageAttributes[pageId]
    },

    getPageTree(state) {
      return rootId => {
        const root = rootId ? rootId : ROOT_TREE_ID
        return state.pageTrees[root]
      }
    }
  }
}
