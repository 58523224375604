import Vue from "vue"
import ApiClient from "@/lib/api-client"
import { Exercise } from "@shared/types"

import { unique } from "@/lib/utils"
import { setLocale, DEFAULT } from "@/store/i18n"

type ExerciseSwap = {
  exerciseId: number
  alternateId: number
}

type SubscriberState = {
  blockedUserIds: number[]
  exerciseSwaps: { [key: number]: number }
}

const getInitialState = (): SubscriberState => ({
  blockedUserIds: [],
  exerciseSwaps: {}
})

export default {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    setBlockedUserIds(state: SubscriberState, userIds: number[]) {
      state.blockedUserIds = userIds
    },

    addUserIdToBlockList(state: SubscriberState, userId: number) {
      state.blockedUserIds = unique(state.blockedUserIds.concat(userId))
    },

    setExerciseSwap(
      state: SubscriberState,
      { exerciseId, alternateId }: ExerciseSwap
    ) {
      if (exerciseId && alternateId) {
        Vue.set(state.exerciseSwaps, exerciseId, alternateId)
      }
    },

    removeExerciseSwap(state: SubscriberState, exerciseId: number) {
      Vue.delete(state.exerciseSwaps, exerciseId)
    },

    reset(state: SubscriberState) {
      Object.assign(state, getInitialState())
    }
  },

  getters: {
    hasSwappedExercise(state: SubscriberState) {
      return (exerciseId: number): boolean => !!state.exerciseSwaps[exerciseId]
    },

    getSwappedExercise(state: SubscriberState) {
      return (exercise: Exercise): Exercise | null => {
        const alternateId = state.exerciseSwaps[exercise?.id]

        return alternateId
          ? (exercise.alternates || []).find(
              alternate => alternate.id === alternateId
            ) || null
          : null
      }
    }
  },

  actions: {
    blockSubscriber({ commit }: any, userId: number): Promise<void> {
      return ApiClient.post("/subscribers/block", {
        user_id: userId
      }).then(() => {
        commit("addUserIdToBlockList", userId)
      })
    },

    updateSubscriber(
      { commit }: any,
      {
        language,
        canReceiveMessagesFromMembers
      }: {
        language: string | undefined
        canReceiveMessagesFromMembers: boolean | undefined
      }
    ) {
      const params = {} as {
        language?: string
        can_receive_messages_from_members?: boolean
      }

      if (language) {
        params.language = language
      }

      if (canReceiveMessagesFromMembers !== undefined) {
        params.can_receive_messages_from_members = canReceiveMessagesFromMembers
      }

      return ApiClient.put(`/subscribers`, params).then(
        async ({ data }: any) => {
          const updatedLanguage = data.subscriber.language

          if (updatedLanguage && updatedLanguage !== DEFAULT) {
            await setLocale(updatedLanguage)
          }

          commit(
            "auth/setUser",
            {
              language: updatedLanguage,
              canReceiveMessagesFromMembers:
                data.subscriber.can_receive_messages_from_members
            },
            { root: true }
          )

          return data.subscriber
        }
      )
    },

    swapExercise(
      { commit }: any,
      { exerciseId, alternateId }: ExerciseSwap
    ): Promise<void> {
      return ApiClient.post(`/subscribers/swap_exercise`, {
        exercise_id: exerciseId,
        alternate_id: alternateId
      }).then(
        ({ data }: { data: { exercise_id: string; alternate_id: string } }) => {
          commit("setExerciseSwap", {
            exerciseId: data.exercise_id,
            alternateId: data.alternate_id
          })
        }
      )
    },

    resetExercise({ commit }: any, exerciseId: number): Promise<void> {
      return ApiClient.post(`/subscribers/reset_exercise`, {
        exercise_id: exerciseId
      }).then(() => {
        commit("removeExerciseSwap", exerciseId)
      })
    }
  }
}
