import ApiClient from "@/lib/api-client"
import Vue from "vue"

import { trackGTMEvent, GTM_EVENTS } from "@/lib/gtm-utils"
import { FIELD_TYPES, forEachFormField } from "@/lib/form-utils"
import { isBoolean } from "@/lib/utils"

const generateFieldSubmissionData = field => ({
  form_field_id: field.id,
  value:
    field.field_type === FIELD_TYPES.ACCEPTANCE
      ? false
      : field.is_required && field.label == "Rounds Achieved" // TODOB: remove this after mering PR https://github.com/FitterApp/fitter-pwa/pull/1049
      ? field.default_value || 1
      : field.default_value || "",
  image: null,
  field_type: field.field_type,
  value_type: field.value_type,
  label: field.label
})

const generateFormSubmissionValues = form => {
  const result = {}

  forEachFormField(
    form,
    field => (result[field.id] = generateFieldSubmissionData(field))
  )

  return result
}

export default {
  namespaced: true,

  state: {
    forms: {},
    formSubmissionValues: {}, // fieldId => data
    captureStatuses: {}, // captureUid => [status]
    submissionsCount: {} // formId => Number
  },

  mutations: {
    setForm(state, form) {
      Vue.set(state.forms, form.id, form)
    },

    setForms(state, forms) {
      forms.forEach(f => {
        Vue.set(state.forms, f.id, f)
      })
    },

    updateFormSubmissionValue(state, { formId, fieldId, data }) {
      Vue.set(state.formSubmissionValues[formId], fieldId, data)
    },

    setFormSubmissionValues(state, { formId, values }) {
      Vue.set(state.formSubmissionValues, formId, values)
    },

    resetFormSubmissionValues(state, formId) {
      Vue.set(
        state.formSubmissionValues,
        formId,
        generateFormSubmissionValues(state.forms[formId])
      )
      state.captureStatuses = {}
    },

    setCaptureStatus(state, { uid, status, groupsNumber, groupIndex }) {
      const prevStatuses = state.captureStatuses[uid] || []
      const statuses = []

      for (let i = 0; i < groupsNumber; i++) {
        if (groupIndex === i) {
          statuses[i] = status
        } else {
          statuses[i] = prevStatuses[i] ? prevStatuses[i] : null
        }
      }

      Vue.set(state.captureStatuses, uid, statuses)
    },

    setSubmissionsCount(state, { formId, submissionsCount }) {
      Vue.set(state.submissionsCount, formId, submissionsCount)
    },

    increaseSubmissionsCount(state, formId) {
      const count = state.submissionsCount[formId] || 0
      Vue.set(state.submissionsCount, formId, count + 1)
    }
  },

  actions: {
    async submitForm(
      { getters, dispatch, commit },
      { formId, trigger, triggeringItem, pageId }
    ) {
      let form = getters.formById(formId)

      if (!form) {
        form = await dispatch("fetchForm", formId)
      }

      const values = []

      forEachFormField(form, f => {
        const submitValue =
          getters.submissionValuesByForm(formId)[f.id] ||
          generateFieldSubmissionData(f)

        const v = submitValue.value

        values.push({
          ...submitValue,
          value: isBoolean(v) ? String(v) : v
        })
      })

      return ApiClient.post(`/forms/${formId}/submit`, {
        triggering_item: triggeringItem,
        trigger,
        submitted_values: values,
        page_id: pageId
      }).then(res => {
        commit("increaseSubmissionsCount", formId)

        trackGTMEvent(GTM_EVENTS.FORM_SUBMIT, {
          data: {
            form: {
              id: form.id,
              title: form.title
            },
            submission: {
              id: res.data.submission_id,
              data: values.reduce((acc, field) => {
                if (field.label) {
                  acc[`${field.label}`] = field.value
                }

                return acc
              }, {})
            }
          },
          user: res.data.subscriber_uuid
            ? { id: res.data.subscriber_uuid }
            : null
        })

        return res
      })
    },

    fetchForm({ commit }, formId) {
      return ApiClient.get(`/forms/${formId}`).then(res => {
        const form = res.data
        if (form) {
          commit("setForm", form)
          commit("setFormSubmissionValues", {
            formId,
            values: generateFormSubmissionValues(form)
          })
        }

        return form
      })
    },

    fetchFormSubmissions(_, { formId, blockId, page = 1 }) {
      let path

      if (formId) {
        path = `/forms/${formId}/submissions`
      } else if (blockId) {
        path = `/forms_submissions_dynamic/${blockId}`
      } else {
        return
      }

      return ApiClient.get(path, {
        params: {
          page: page
        }
      }).then(res => {
        return res.data
      })
    },

    fetchFormSubmission(_, { formId, formSubmissionId }) {
      return ApiClient.get(
        `/forms/${formId}/submissions/${formSubmissionId}`
      ).then(res => res.data.form_submission)
    },

    fetchSubmissionsCount({ commit }, formId) {
      return ApiClient.get(`/forms/${formId}/submissions_count`).then(res => {
        commit("setSubmissionsCount", {
          formId,
          submissionsCount: res.data.submissions_count
        })

        return res.data.submissions_count
      })
    },

    async hasSubmittedForm({ state, dispatch }, formId) {
      let submissionsCount = state.submissionsCount[formId]
      if (submissionsCount === undefined) {
        submissionsCount = await dispatch("fetchSubmissionsCount", formId)
      }

      return submissionsCount > 0
    },

    updateSubmissionValues(_, { formId, formSubmissionId, values }) {
      return ApiClient.put(
        `/forms/${formId}/submissions/${formSubmissionId}/values`,
        {
          submission_values: values
        }
      ).then(res => res.data)
    },

    fetchExerciseByItemUid(_, workoutGroupItemUid) {
      return ApiClient.get(
        `/workout_group_items/${workoutGroupItemUid}/exercise`
      ).then(res => res.data.exercise)
    },

    fetchFormsSubmissionsNumber(_, form_type) {
      return ApiClient.get("/forms_submissions_number", {
        params: { form_type }
      })
    }
  },

  getters: {
    formByTriggerAndItem(_, getters) {
      return ({ trigger, itemId, itemType }) =>
        getters.fetchedForms.find(
          f =>
            f.trigger === trigger &&
            f.included_items.find(i => i.type === itemType && i.id === itemId)
        )
    },

    fetchedForms(state) {
      return Object.values(state.forms)
    },

    formById(state) {
      return formId => state.forms[formId] || null
    },

    authSignedUpForm(_, getters) {
      return getters.fetchedForms.find(f => f.trigger === "auth_signed_up")
    },

    submissionValuesByForm(state) {
      return formId => state.formSubmissionValues[formId] || {}
    },

    captureStatusesByUid(state) {
      return uid => state.captureStatuses[uid] || []
    },

    hasAnyCaptures(state) {
      return Object.keys(state.captureStatuses).length > 0
    },

    firstCaptureEmptyIndex(_, getters) {
      return uid => {
        const statuses = getters.captureStatusesByUid(uid) || []
        const firstIndex = statuses.findIndex(s => !s)
        if (firstIndex > -1 && firstIndex < statuses.length) {
          return firstIndex
        } else {
          return 0
        }
      }
    },

    formSubmissionsCount(state) {
      return formId => state.submissionsCount[formId] || 0
    }
  }
}
