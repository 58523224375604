import { AppGlobalState } from "@shared/types"
import { VARIABLES } from "@/lib/globals"
import { get, LS_KEYS, set } from "@/lib/local-storage"

export const defaultAppGlobalState: AppGlobalState = {
  publication_key: null,
  app: {
    name: "",
    short_name: "",
    icon: { image: { uuid: "" } },
    slug: "",
    url: "",
    primary_domain: "",
    legal_privacy_policy_url: "",
    legal_terms_and_conditions_url: "",
    legal_requires_acceptance: false,
    google_analytics_id: "",
    google_tag_manager_id: "",
    rewardful_id: "",
    language: "",
    has_published_packs: false
  },
  meta: {
    url: "",
    title: "",
    og_title: "",
    og_description: "",
    og_image: { image: { uuid: "" } },
    icons: [],
    splash_images: [],
    splash_image: { image: { uuid: "" } },
    splash_color: ""
  },
  theme: {
    app_look: "",
    card_style: "",
    heading_font: "",
    body_font: "",
    default_color_mode: "",
    show_fitter_badge: false,
    primary_color: { hex: "", rgb: "" },
    secondary_color: { hex: "", rgb: "" },
    light_color: { hex: "", rgb: "" },
    dark_color: { hex: "", rgb: "" },
    global_css: "",
    global_head_code: "",
    global_body_code: ""
  },
  access: {
    is_subscriber_only: false,
    require_sign_in: false,
    is_private: false,
    app_products: [],
    allow_anonymous_browsing: false
  },
  payments: {
    can_accept_payments: false,
    stripe_account_id: null,
    country: null,
    default_currency: null,
    has_enabled_tax_collection: null,
    tax_behavior: null
  },
  account: {
    id: 0,
    name: "",
    email: "",
    verified: false,
    timezone: "",
    supports_legacy_domain: false
  },
  subscription: {
    active: false,
    is_free: false,
    is_base: false,
    is_pro: false
  },
  trial: {
    active: false
  },
  toolbar: null,
  tabbar: {
    links: []
  },
  messaging: {
    member_to_admin_messaging_enabled: false,
    member_to_member_messaging_enabled: false
  }
}

// This function is duplicated in app/src/lib/utils.ts to prevent increasing the entry bundle size
const isObject = (obj: any): obj is object =>
  typeof obj === "object" && obj !== null

const globalState = (): AppGlobalState => {
  try {
    const storageItem: AppGlobalState | undefined = get(LS_KEYS.GLOBAL_STATE)
    const windowItem: AppGlobalState | undefined =
      window[VARIABLES.GLOBAL_STATE]

    const state = isObject(storageItem)
      ? storageItem
      : isObject(windowItem)
      ? windowItem
      : {}

    return { ...defaultAppGlobalState, ...state }
  } catch (error) {
    console.error("Error fetching global state:", error)
    return defaultAppGlobalState
  }
}

const globalFileUrl = (extension: string): string => {
  return `${
    process.env.VUE_APP_APP_DATA_PATH
  }/global.${extension}?ts=${Date.now()}`
}

const fetchGlobalJson = async (): Promise<AppGlobalState | null> => {
  const response = await fetch(globalFileUrl("json"))
  if (response.status === 404) return null
  return await response.json()
}

const fetchGlobalJs = async (): Promise<AppGlobalState> => {
  const response = await fetch(globalFileUrl("js"))
  const body = await response.text()
  return JSON.parse(
    body
      .replace("window.FIT_globalState", "")
      .trim()
      .replace("=", "")
  )
}

const refreshGlobalState = async (): Promise<AppGlobalState> => {
  const data = (await fetchGlobalJson()) ?? (await fetchGlobalJs())
  set(LS_KEYS.GLOBAL_STATE, data)
  return data
}

export { globalState, refreshGlobalState }
