import Vue from "vue"
import VueI18n from "vue-i18n"
import dayjs from "dayjs"
import messages from "../../locales/en.json"

Vue.use(VueI18n)

const DEFAULT = "default"

export const DEFAULT_LOCALE = "en"

const loadedLanguages = [DEFAULT_LOCALE]

function setI18nLanguage(lang) {
  i18n.locale = lang
  dayjs.locale(lang)
  return lang
}

export async function setLocale(lang) {
  if (i18n.locale === lang) {
    return setI18nLanguage(lang)
  }

  if (loadedLanguages.includes(lang)) {
    return setI18nLanguage(lang)
  }

  const loadedLocale = await import(
    /* webpackChunkName: "lang-[request]" */ `../../locales/${lang}.json`
  )

  i18n.setLocaleMessage(lang, loadedLocale)
  loadedLanguages.push(lang)
  return setI18nLanguage(lang)
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: DEFAULT_LOCALE, // set locale
  fallbackLocale: DEFAULT_LOCALE,
  messages: {
    en: messages
  } // set locale messages
})

export default i18n

export { DEFAULT }
