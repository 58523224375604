const metaTag = (name, content) => ({
  hid: name,
  vmid: name,
  name,
  content
})

const iosStartupMediaMap = {
  portrait: "screen and (orientation: portrait)",
  landscape: "screen and (orientation: landscape)",
  "640x1136":
    "screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
  "750x1334":
    "screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
  "828x1792":
    "screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
  "1125x2436":
    "screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)",
  "1242x2208":
    "screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)",
  "1242x2688":
    "screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)",
  "1536x2048":
    "screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
  "1668x2224":
    "screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
  "1668x2388":
    "screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
  "2048x2732":
    "screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
}

const iosStartupImage = (splash_images, size, _imageKey = null) => {
  const imageKey = _imageKey || size

  return {
    rel: "apple-touch-startup-image",
    media: iosStartupMediaMap[size],
    href: splash_images[imageKey] ? splash_images[imageKey].src : ""
  }
}

const iosTouchIcon = (icons, size) => ({
  rel: "apple-touch-icon",
  sizes: size,
  href: icons[size] ? icons[size].src : ""
})

export const pageMeta = ({ title, ogTitle, description, image, url }) => ({
  title: title,
  meta: [
    metaTag("og:title", ogTitle),
    metaTag("og:url", url),
    description ? metaTag("og:description", description) : null,
    image ? metaTag("og:image", image ? image.url : "") : null
  ].filter(x => x)
})

export default config =>
  config.meta && config.app
    ? {
        title: config.meta.og_title ? config.meta.og_title : config.meta.title,
        titleTemplate: `%s - ${config.app.name}`,
        link: [
          {
            hid: "favicon",
            rel: "icon",
            href: config.meta.icons["72x72"]
              ? config.meta.icons["72x72"].src
              : "/favicon.ico"
          },
          iosTouchIcon(config.meta.icons, "72x72"),
          iosTouchIcon(config.meta.icons, "167x167"),
          iosTouchIcon(config.meta.icons, "180x180"),
          iosTouchIcon(config.meta.icons, "512x512"),

          iosStartupImage(config.meta.splash_images, "portrait", "1125x2436"),
          iosStartupImage(config.meta.splash_images, "landscape", "1125x2436"),
          iosStartupImage(config.meta.splash_images, "640x1136"),
          iosStartupImage(config.meta.splash_images, "750x1334"),
          iosStartupImage(config.meta.splash_images, "828x1792"),
          iosStartupImage(config.meta.splash_images, "1125x2436"),
          iosStartupImage(config.meta.splash_images, "1242x2208"),
          iosStartupImage(config.meta.splash_images, "1242x2688"),
          iosStartupImage(config.meta.splash_images, "1536x2048"),
          iosStartupImage(config.meta.splash_images, "1668x2388"),
          iosStartupImage(config.meta.splash_images, "1668x2224"),
          iosStartupImage(config.meta.splash_images, "2048x2732")
        ],
        meta: [
          metaTag(
            "description",
            config.meta.og_description ? config.meta.og_description : ""
          ),
          metaTag(
            "theme-color",
            config.theme.primary_color?.hex || config.theme.brand_color?.hex
          ),
          metaTag(
            "name",
            config.meta.og_title ? config.meta.og_title : config.meta.title
          ),
          metaTag("lang", "en"),
          metaTag("author", config.app.name),
          metaTag("og:title", config.meta.og_title),
          metaTag("og:url", config.meta.url),
          metaTag("og:description", config.meta.og_description),
          metaTag("og:site_name", config.app.name),
          metaTag(
            "og:image",
            config.meta.og_image
              ? config.meta.og_image.image.url
              : config.meta.share_image
              ? config.meta.share_image.image.url
              : ""
          ),
          metaTag("og:type", "website"),
          metaTag("apple-mobile-web-app-title", config.meta.og_title)
        ]
      }
    : {}
