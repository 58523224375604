<template>
  <v-page
    :page-id="portalId"
    :white="whiteBg"
    no-padding
    :dark-mode="props.color_mode === COLOR_MODES.DARK"
  >
    <transition name="fade" mode="out-in">
      <template v-if="isLoading">
        <div class="container pt-1">
          <placeholder-rect width="100%" height="250px" />
          <placeholder-rect width="80%" height="32px" />
          <placeholder-rect width="35%" height="32px" />
        </div>
      </template>
      <template v-else-if="errorCode && errorCode === 404">
        <div class="container">
          <div
            class="text-center h-full px-3 d-flex flex-column justify-content-center"
          >
            <toolbar transparent back fixed :logo="false" />
            <v-icon icon="help_outline" class="text-grey-50" size="3rem" />
            <h2 class="mb-0">{{ $t("pages.dynamic.pageNotFoundTitle") }}</h2>
            <p class="lead mt-1 mb-4">
              {{ $t("pages.dynamic.pageNotFoundContent") }}
            </p>

            <v-button primary block to="/">
              {{ $t("pages.dynamic.takeMeHome") }}
            </v-button>
          </div>
        </div>
      </template>
      <template v-else-if="template">
        <app-page
          :props="props"
          :template="template"
          :no-padding-bottom="!showBottomPadding(props)"
        />
      </template>
    </transition>
  </v-page>
</template>

<script>
import { trackGTMEvent, GTM_EVENTS } from "@/lib/gtm-utils"

import { isPresent } from "@/lib/utils"
import { showBottomPadding } from "@/lib/page-utils"
import { getCachedPageByPath } from "@/lib/page-cache"
import fetchPageData from "@/lib/fetch-page-data"
import { COLOR_MODES } from "@/lib/stream-utils"

import PlaceholderRect from "@/components/PlaceholderRect"
import AppPage from "@/components/AppPage"

export default {
  components: {
    PlaceholderRect,
    AppPage,
    Toolbar: () => import("@/components/Toolbar")
  },

  data() {
    return {
      template: null,
      props: {},

      isLoading: false,
      hasResolvedData: false,

      errorCode: null,
      COLOR_MODES
    }
  },

  computed: {
    portalId() {
      return `dynamic-page-${this._uid}`
    },

    path() {
      return isPresent(this.forcePath) ? this.forcePath : this.$route.path
    },

    whiteBg() {
      return this.template === "WorkoutTemplate"
    }
  },

  methods: {
    showBottomPadding
  },

  mounted() {
    const path = this.forcePath || this.$route.path

    this.isLoading = true

    getCachedPageByPath(path).then(pageCache => {
      if (this.hasResolvedData) return

      if (isPresent(pageCache)) {
        this.template = pageCache.template
        this.props = pageCache.props

        this.isLoading = false
      } else {
        this.isLoading = true
      }
    })

    fetchPageData(path)
      .then(data => {
        this.template = data.template
        this.props = data.props

        this.hasResolvedData = true
      })
      .catch(err => {
        this.errorCode = err.response.status

        trackGTMEvent(GTM_EVENTS.CONTENT_ERROR, {
          data: {
            error_code: this.errorCode,
            href: window.location.href
          }
        })
      })
      .finally(() => {
        this.isLoading = false
      })
  }
}
</script>
