import joinUrl from "url-join"
import { v4 as uuid } from "uuid"

import { NAVIGATION_EVENTS } from "@/plugins/router/shared"
import { trackGTMEvent, GTM_EVENTS } from "@/lib/gtm-utils"

import ApiClient from "@/lib/api-client"
import { isPresent } from "@/lib/utils"
import * as localStorage from "@/lib/local-storage"
import * as sessionStorage from "@/lib/session-storage"

const SESSION_KEY = "fit-session-ts"
const DEVICE_KEY = "fit-device-id"

const sendBeacon = (path, data) =>
  navigator.sendBeacon(joinUrl(process.env.VUE_APP_API_BASE_URL, path), data)
const endSession = data => sendBeacon("/activity/end_session", data)
const resumeSession = data => sendBeacon("/activity/resume_session", data)

// Must match: /backend/app/models/account_app_activity.rb
export const ACTIVITY_TYPES = {
  PAGE_VIEWED: "page_viewed",
  FORM_VIEWED: "form_viewed",
  FORM_PROGRESSED: "form_progressed",
  FORM_SUBMITTED: "form_submitted",
  FORM_ABORTED: "form_aborted",
  LIVESTREAM_JOINED: "livestream_joined",
  LIVESTREAM_LEFT: "livestream_left",
  CHECKOUT_PAST_DUE_PROMPTED: "checkout_past_due_prompted",
  CHECKOUT_ERROR: "checkout_error",
  CHECKOUT_COMPLETED: "checkout_completed",
  CHECKOUT_ABORTED: "checkout_aborted",
  CHECKOUT_STARTED: "checkout_started",
  CHECKOUT_PROGRESSED: "checkout_progressed",
  VIDEO_STARTED: "video_started",
  VIDEO_WATCHED: "video_watched",
  PUSH_NOTIFICATION_PROMPTED: "push_notification_prompted",
  PUSH_NOTIFICATION_DISMISSED: "push_notification_dismissed"
}

const trackActivity = ({ activityType, data }) =>
  ApiClient.put("/activity", {
    activity_type: activityType,
    data: data || {},
    ts: +new Date()
  })

// We shouldn't track session end / resume while in checkout flow as Stripe
// performs a redirect that is valid, and therefore its not really ending the session
const isInCheckout = () => window.location.hash?.includes("#checkout")

export default {
  namespaced: true,

  state: {
    sessionId: sessionStorage.getItem(SESSION_KEY),
    deviceId: localStorage.get(DEVICE_KEY)
  },

  mutations: {
    setDeviceId(state, deviceId) {
      if (isPresent(deviceId)) {
        localStorage.set(DEVICE_KEY, deviceId)
        state.deviceId = deviceId
      }
    },

    setSessionId(state, sessionId) {
      if (isPresent(sessionId)) {
        sessionStorage.setItem(SESSION_KEY, sessionId)
        state.sessionId = sessionId
      }
    }
  },

  actions: {
    async initializeSession({ state, commit, rootState, dispatch }) {
      const sessionStorageSession = sessionStorage.getItem(SESSION_KEY)
      const localStorageDevice = localStorage.get(DEVICE_KEY)

      const deviceId =
        isPresent(localStorageDevice) && localStorageDevice !== "null"
          ? localStorageDevice
          : uuid()

      commit("setDeviceId", deviceId)

      const sessionId =
        isPresent(sessionStorageSession) && sessionStorageSession !== "null"
          ? sessionStorageSession
          : +new Date()

      commit("setSessionId", sessionId)

      const beaconData = new FormData()
      beaconData.append("session_id", state.sessionId)
      beaconData.append("device_id", state.deviceId)
      beaconData.append("account_app_slug", rootState.global?.app.slug)
      beaconData.append("origin_href", window.location.href)

      // https://www.igvita.com/2015/11/20/dont-lose-user-and-app-state-use-page-visibility/
      document.addEventListener("visibilitychange", () => {
        if (isInCheckout()) return

        if (document.visibilityState == "hidden") {
          endSession(beaconData)
        } else if (document.visibilityState == "visible") {
          resumeSession(beaconData)
        }
      })

      window.addEventListener("beforeunload", () => {
        if (isInCheckout()) return
        endSession(beaconData)
      })

      dispatch("trackActivity", {
        activityType: ACTIVITY_TYPES.PAGE_VIEWED,
        data: { navigation_type: "initial_load" }
      })
    },

    trackActivity(_, { activityType, data }) {
      return trackActivity({ activityType, data })
    },

    trackPagePush() {
      trackActivity({
        activityType: ACTIVITY_TYPES.PAGE_VIEWED,
        data: { navigation_type: NAVIGATION_EVENTS.PUSH }
      })
    },

    trackPagePop() {
      trackActivity({
        activityType: ACTIVITY_TYPES.PAGE_VIEWED,
        data: { navigation_type: NAVIGATION_EVENTS.POP }
      })
    },

    trackFormViewed(_, { form, triggeringItem }) {
      trackActivity({
        activityType: ACTIVITY_TYPES.FORM_VIEWED,
        data: {
          form: {
            id: form.id,
            title: form.title,
            trigger: form.trigger,
            is_auto_generated: !!form.is_auto_generated,
            show_intro_screen: form.show_intro_screen,
            intro_heading: form.intro_heading,
            intro_description: form.intro_description
          },
          ...(triggeringItem
            ? {
                triggering_item: {
                  item_id: triggeringItem.itemId,
                  item_type: triggeringItem.itemType
                }
              }
            : {})
        }
      })
    },

    trackFormProgressed(_, { form, group, direction }) {
      trackActivity({
        activityType: ACTIVITY_TYPES.FORM_PROGRESSED,
        data: {
          form: {
            id: form.id,
            title: form.title,
            trigger: form.trigger
          },
          form_group: {
            id: group.id,
            title: group.title,
            description: group.descripton
          },
          direction
        }
      })
    },

    trackFormAborted(_, { form }) {
      trackActivity({
        activityType: ACTIVITY_TYPES.FORM_ABORTED,
        data: {
          form: {
            id: form.id,
            title: form.title,
            trigger: form.trigger
          }
        }
      })
    },

    trackVideoStarted(_, { video }) {
      trackActivity({
        activityType: ACTIVITY_TYPES.VIDEO_STARTED,
        data: {
          video: {
            id: video.id,
            title: video.title,
            provider: video.provider,
            link: video.link
          }
        }
      })

      trackGTMEvent(GTM_EVENTS.VIDEO_START, {
        data: {
          video: {
            id: video.id,
            title: video.title,
            link: video.link
          }
        }
      })
    },

    trackVideoWatched(_, { video, watchedSeconds, watchedPercentage }) {
      trackActivity({
        activityType: ACTIVITY_TYPES.VIDEO_WATCHED,
        data: {
          video: {
            id: video.id,
            title: video.title,
            provider: video.provider,
            link: video.link
          },
          watched_seconds: Math.round(watchedSeconds),
          watched_percentage: Math.round(watchedPercentage)
        }
      })

      trackGTMEvent(GTM_EVENTS.VIDEO_WATCH, {
        data: {
          video: {
            id: video.id,
            title: video.title,
            link: video.link
          },
          watched_seconds: Math.round(watchedSeconds),
          watched_percentage: Math.round(watchedPercentage)
        }
      })
    }
  }
}
