import { get, set, del, createStore } from "idb-keyval"

import isBuilderContext from "@/lib/is-platform/is-builder-context"

const DB_NAME = "movement.so-app-db"

const pagesStore = createStore(DB_NAME, "page-cache")

const INITIAL_STATE_VARIABLE = "FIT_initialState"

declare global {
  interface Window {
    [INITIAL_STATE_VARIABLE]: any
  }
}

const pageIdKey = (pageId: string): string => `id:${pageId}`
const pagePathKey = (pageId: string): string => `path:${pageId}`
const accessTreeKey = (rootId: string): string => `act:${rootId}`

export const cachePage = (path: string, data: any): boolean => {
  if (isBuilderContext()) return true

  try {
    set(pageIdKey(data.props.page_id), data.props, pagesStore)
    set(pagePathKey(path), data, pagesStore)

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export const removeCachedPage = async (path: string): Promise<boolean> => {
  if (isBuilderContext()) return true

  try {
    const data = await get(pagePathKey(path), pagesStore)

    if (data && data.props) {
      const pageId = data.props.page_id
      await del(pagePathKey(path), pagesStore)
      await del(pageIdKey(pageId), pagesStore)
    }

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export const cacheAccessTree = (rootId: string, data: any): boolean => {
  if (isBuilderContext()) return true

  try {
    set(accessTreeKey(rootId), data, pagesStore)

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export const getCachedPageByPath = async (path: string): Promise<any> => {
  try {
    const cachedPage = await get(pagePathKey(path), pagesStore)

    return cachedPage
      ? cachedPage
      : window[INITIAL_STATE_VARIABLE] && window[INITIAL_STATE_VARIABLE][path]
      ? window[INITIAL_STATE_VARIABLE][path]
      : null
  } catch (e) {
    console.error(e)
    return null
  }
}

export const getCachedPage = async (pageId: string): Promise<any> => {
  try {
    return await get(pageIdKey(pageId), pagesStore)
  } catch (e) {
    console.error(e)
    return null
  }
}

export const getCachedAccessTree = async (rootId: string): Promise<any> => {
  try {
    return await get(accessTreeKey(rootId), pagesStore)
  } catch (e) {
    console.error(e)
    return null
  }
}
