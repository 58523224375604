import { v4 as uuid } from "uuid";
import { DEFAULT_RADIUS_RATIO } from "./block-ratio";
import SUPPORTED_SOCIALS from "./supported-socials.json";
import SUPPORTED_SHARES from "./supported-shares.json";

import {
  Block,
  TypedBlock,
  BlockType,
  IndexedBlock,
  IndexedBlocks,
  OrderedBlocks,
  Social,
} from "./types";

/**
 * Constants
 */
export const DEFAULT_RATIO_VALUE = 100;
export const HERO_HEIGHT_RATIO_OPTIONS = {
  PIXEL: "pixel",
  PERCENT: "percent",
} as const;

export const HERO_IMAGE_DISPLAY_OPTIONS = {
  COVER: "cover",
  CONTAIN: "contain",
} as const;

export const HERO_IMAGE_WIDTH_OPTIONS = {
  CONTAIN: "contain",
  FULL: "full",
} as const;

export const HERO_VIDEO_DISPLAY_OPTIONS = {
  COVER: "cover",
  CONTAIN: "fit",
} as const;

export const BLOCK_POSITIONS = {
  START: "start",
  CENTER: "center",
  END: "end",
} as const;

export const BLOCK_TYPES = {
  HERO_BLOCK: "hero_block",
  COLLECTION_BLOCK: "collection_block",
  TEXT_BLOCK: "text_block",
  AVATAR_BLOCK: "avatar_block",
  BUTTON_BLOCK: "button_block",
  ATTACHMENT_BLOCK: "attachment_block",
  IFRAME_BLOCK: "iframe_block",
  CHAT_BLOCK: "chat_block",
  LIVESTREAM_BLOCK: "livestream_block",
  IMAGE_BLOCK: "image_block",
  SHARE_BLOCK: "share_block",
  WORKOUT_BLOCK: "workout_block",
  ICON_LIST_BLOCK: "icon_list_block",
  MAP_BLOCK: "map_block",
  COMPOSED_BLOCK: "composed_block",
  FORM_SUBMISSION_BLOCK: "form_submission_block",
  SEARCH_BLOCK: "search_block",
  CODE_BLOCK: "code_block",
  SOCIAL_BLOCK: "social_block",
} as const;

export const BLOCK_COLORS = {
  NONE: null,
  WHITE: "white",
  LIGHT_GREY: "light_grey",
  GREY: "grey",
  BLACK: "black",
  PRIMARY: "primary",
  BRAND: "brand",
} as const;

export const SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
} as const;

export const HERO_SIZE_TO_RATIO = {
  [SIZES.SMALL]: 100,
  [SIZES.MEDIUM]: 180,
  [SIZES.LARGE]: 250,
} as const;

export const AVAILABLE_ICONS = {
  DONE: "done",
  FITNESS_CENTER: "fitness_center",
  SCHEDULE: "schedule",
  TIMER: "timer",
  GRID_VIEW: "grid_view",
  SPORTS_BASKETBALL: "sports_basketball",
  CHECK_CIRCLE_OUTLINE: "check_circle_outline",
  EVENT: "event",
  EMOJI_EVENTS: "emoji_events",
  TODAY: "today",
  RESTAURANT: "restaurant",
  SIGNAL_CELLULAR_ALT: "signal_cellular_alt",
  PLAY_ARROW: "play_arrow",
  LIBRARY_BOOKS: "library_books",
  LANGUAGE: "language",
  PLAY_CIRCLE_OUTLINE: "play_circle_outline",
  ONDEMAND_VIDEO: "ondemand_video",
  CHECK_CIRCLE: "check_circle",
  PERSON: "person",
  NAVIGATE_NEXT: "navigate_next",
  DIRECTIONS_RUN: "directions_run",
  SELF_IMPROVEMENT: "self_improvement",
  OPACITY: "opacity",
  GPS_FIXED: "gps_fixed",
  STOP: "stop",
  WATCH_LATER: "watch_later",
  DATE_RANGE: "date_range",
  ACCESSIBILITY: "accessibility",
  WARNING_AMBER: "warning_amber",
  ALL_INCLUSIVE: "all_inclusive",
} as const;

export const BUTTON_SIZES = {
  SMALL: "small",
  REGULAR: "regular",
  LARGE: "large",
} as const;

export const BASE_WIDTH = 375;

export const NODE_TYPES = {
  DATA_FIELD: "data-field",
} as const;

/**
 * Types
 */
const generateUUID = () => uuid();

export const indexBlocks = (
  acc,
  blocks: OrderedBlocks,
  parent: any = null
): IndexedBlocks => {
  blocks.map((block: Block) => {
    const indexedBlock: IndexedBlock = { ...block, blocks: [] };

    indexedBlock.parent = parent ? parent.uuid : block.parent;

    if (block.blocks && block.blocks.length > 0) {
      indexBlocks(acc, block.blocks, block);

      indexedBlock.blocks = block.blocks.map((b) => b.uuid);
    }

    acc[block.uuid] = indexedBlock;
  });

  return acc;
};

export const getBlockDefault = <T extends BlockType>(
  type: T
): TypedBlock<T> => {
  switch (type) {
    case BLOCK_TYPES.HERO_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.HERO_BLOCK, {
        image: null,
        video: null,
        audio: null,
        is_overlaid: false,
        height_ratio: DEFAULT_RATIO_VALUE,
        height_ratio_unit: HERO_HEIGHT_RATIO_OPTIONS.PIXEL,
        border_radius_ratio: DEFAULT_RADIUS_RATIO,
        image_opacity_ratio: DEFAULT_RATIO_VALUE,
        background_size: HERO_IMAGE_DISPLAY_OPTIONS.COVER,
        background_width: HERO_IMAGE_WIDTH_OPTIONS.CONTAIN,
        text_position_x: BLOCK_POSITIONS.START,
        text_position_y: BLOCK_POSITIONS.END,
        image_position_x: BLOCK_POSITIONS.CENTER,
        image_position_y: BLOCK_POSITIONS.CENTER,
        video_fit: HERO_VIDEO_DISPLAY_OPTIONS.CONTAIN,
        video_plays_inline: true,
      }) as TypedBlock<T>;
    case BLOCK_TYPES.BUTTON_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.BUTTON_BLOCK, {
        text: "Button Text",
        display: "block",
        variant: "primary",
        style: "raised",
        size: "regular",
      }) as TypedBlock<T>;
    case BLOCK_TYPES.COLLECTION_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.COLLECTION_BLOCK, {
        direction: "vertical",
        card_style: "raised",
        card_display: "vertical",
        column_width_ratio: DEFAULT_RATIO_VALUE,
        image_size_ratio: DEFAULT_RATIO_VALUE,
        image_shown: true,
        collection: {
          is_detached: true,
          uuid: generateUUID(),
          items: [],
          is_dynamic: true,
          dynamic_limit: 5,
          dynamic_offset: 0,
          dynamic_direction: null,
          duration_period: null,
          duration_interval: "hours",
          dynamic_include_current: true,
          dynamic_order_by: "created_desc",
          dynamic_content_type: null,
          dynamic_with_all_tags: true,
          dynamic_progress: null,
          dynamic_schedule_type: null,
          dynamic_timezone: "app",
          tags: [],
        },
      }) as TypedBlock<T>;
    case BLOCK_TYPES.ATTACHMENT_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.ATTACHMENT_BLOCK, {
        card_style: "raised",
        height_ratio: DEFAULT_RATIO_VALUE,
      }) as TypedBlock<T>;
    case BLOCK_TYPES.IFRAME_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.IFRAME_BLOCK, {
        height_ratio: DEFAULT_RATIO_VALUE,
      }) as TypedBlock<T>;
    case BLOCK_TYPES.MAP_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.MAP_BLOCK, {
        height_ratio: DEFAULT_RATIO_VALUE,
      }) as TypedBlock<T>;
    case BLOCK_TYPES.ICON_LIST_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.ICON_LIST_BLOCK, {
        icon_list: [{ icon: "grid_view", text: "" }],
        text_color: BLOCK_COLORS.GREY,
      }) as TypedBlock<T>;
    case BLOCK_TYPES.WORKOUT_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.WORKOUT_BLOCK, {
        workout: {
          uuid: generateUUID(),
          workout_groups: [
            {
              rounds: 1,
              title: "Workout",
              is_rest: false,
              uuid: generateUUID(),
            },
          ],
        },
      }) as TypedBlock<T>;
    case BLOCK_TYPES.SHARE_BLOCK:
      const defaultShares = Object.values(SUPPORTED_SHARES).reduce(
        (acc: { [key: string]: boolean }, social: Social) => {
          acc[social.name] = social.default || false;

          return acc;
        },
        {}
      ) as { [key: string]: boolean };

      return buildBlockDefault(
        BLOCK_TYPES.SHARE_BLOCK,
        defaultShares
      ) as TypedBlock<T>;
    case BLOCK_TYPES.IMAGE_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.IMAGE_BLOCK, {
        width_ratio: DEFAULT_RATIO_VALUE,
        align: "center",
      }) as TypedBlock<T>;
    case BLOCK_TYPES.CHAT_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.CHAT_BLOCK, {}) as TypedBlock<T>;
    case BLOCK_TYPES.LIVESTREAM_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.LIVESTREAM_BLOCK, {
        livestream: { provider: "youtube" },
      }) as TypedBlock<T>;
    case BLOCK_TYPES.COMPOSED_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.COMPOSED_BLOCK, {}) as TypedBlock<T>;
    case BLOCK_TYPES.FORM_SUBMISSION_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.FORM_SUBMISSION_BLOCK, {
        forms: [],
        is_advanced_mode: false,
        page_content_type: null,
        dynamic_with_all_tags: true,
        form_tags: [],
      }) as TypedBlock<T>;
    case BLOCK_TYPES.SEARCH_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.SEARCH_BLOCK, {
        page_content_types: [],
        with_all_tags: true,
        search_tags: [],
        display_tags: [],
        with_display_tags: false,
      }) as TypedBlock<T>;

    case BLOCK_TYPES.CODE_BLOCK:
      return buildBlockDefault(BLOCK_TYPES.CODE_BLOCK, {
        code: null,
      }) as TypedBlock<T>;

    case BLOCK_TYPES.SOCIAL_BLOCK:
      const defaultSocials = Object.values(SUPPORTED_SOCIALS).reduce(
        (acc: { [key: string]: null }, social: Social) => {
          acc[social.name] = null;

          return acc;
        },
        {}
      ) as { [key: string]: null };

      return buildBlockDefault(
        BLOCK_TYPES.SOCIAL_BLOCK,
        defaultSocials
      ) as TypedBlock<T>;

    default:
      // Ensure this function always returns something
      return buildBlockDefault(BLOCK_TYPES.TEXT_BLOCK, {
        content: "<p></p>",
        size_ratio: DEFAULT_RATIO_VALUE,
        tag: "p",
      }) as TypedBlock<T>;
  }
};

const spaceAttributes = (defaultValue = DEFAULT_RATIO_VALUE) => ({
  space_x_ratio: defaultValue,
  space_top_ratio: defaultValue,
  space_bottom_ratio: defaultValue,
});

const buildBlockDefault = (type: BlockType, data: {}): Block =>
  ({
    type,
    uuid: generateUUID(),
    ...spaceAttributes(
      (
        [
          BLOCK_TYPES.HERO_BLOCK,
          BLOCK_TYPES.IFRAME_BLOCK,
          BLOCK_TYPES.IMAGE_BLOCK,
          BLOCK_TYPES.CHAT_BLOCK,
          BLOCK_TYPES.LIVESTREAM_BLOCK,
          BLOCK_TYPES.MAP_BLOCK,
          BLOCK_TYPES.WORKOUT_BLOCK,
          BLOCK_TYPES.COMPOSED_BLOCK,
          BLOCK_TYPES.FORM_SUBMISSION_BLOCK,
          BLOCK_TYPES.CODE_BLOCK,
        ] as Array<string>
      ).includes(type)
        ? 0
        : DEFAULT_RATIO_VALUE
    ),
    data,
  } as Block);

export const generateOrderedBlocks = (
  indexedBlocks: IndexedBlocks
): OrderedBlocks => {
  let rootBlocks: Array<IndexedBlock> = [];

  const groupedParent: { [key: string]: Block[] } = Object.values(indexedBlocks)
    .sort((a, b) => a.position - b.position)
    .reduce((acc, block) => {
      if (!block.blocks) block.blocks = [];

      if (!block.parent) {
        rootBlocks.push(block);
        block.position = rootBlocks.length;

        return acc;
      }

      if (!acc[block.parent]) acc[block.parent] = [];

      block.position = acc[block.parent].length + 1;

      acc[block.parent].push({ ...block });

      return acc;
    }, {});

  Object.entries(groupedParent).map(([parentUuid, childBlocks]) => {
    childBlocks.map((childBlock) => {
      if (groupedParent[childBlock.uuid]) {
        groupedParent[parentUuid][childBlock.position - 1].blocks =
          groupedParent[childBlock.uuid];
      }
    });
  });

  return rootBlocks.map((rootBlock) => ({
    ...rootBlock,
    blocks: groupedParent[rootBlock.uuid] || [],
  }));
};
