import Vue from "vue"
import VueRouter, { Route, NavigationGuardNext, RouteConfig } from "vue-router"

import isBuilderContext from "@/lib/is-platform/is-builder-context"

import DynamicPage from "@/pages/Dynamic.vue"

import store from "@/store"

import scrollBehavior from "@/plugins/router/scroll-behaviour"
import { BUY_PATH } from "@/lib/checkout-utils"
import EventBus, { EVENTS } from "@/lib/event-bus"
import { MANAGED_PAGE_PREFIX } from "@/lib/constants"
import { MVT_EVENTS } from "@/lib/globals"
import { trackGTMEvent, GTM_EVENTS } from "@/lib/gtm-utils"
import { standardizeUrl } from "@/lib/url-helpers"

if (isBuilderContext()) {
  // Initializing the main apps VueRouter can cause conflicts with the Builder mocks.
  // Preventing the initialization of the router in the Builder prevents bugs, but ideally
  // this file should not be included in the Builder context.
  if (process.env.NODE_ENV !== "production") {
    console.warn("Router initialized in the Builder context")
  }
} else {
  Vue.use(VueRouter)
}

export const requireAuthentication = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  // vLink or GenericTemplate will take care of showing the auth modal for
  // generic pages
  if (!to.path.startsWith(MANAGED_PAGE_PREFIX)) {
    return next()
  }

  if (store.getters["auth/hasCheckedAuthentication"]) {
    if (store.getters["auth/isAuthenticated"]) return next()

    if (to.hash.startsWith("#auth")) return next()
    if (from.hash.startsWith("#auth")) return next()

    if (to.hash.startsWith("#checkout")) return next()
    if (from.hash.startsWith("#checkout")) return next()

    if (
      to.matched &&
      to.matched[0] &&
      !!MANAGED_PAGES_REQUIRING_AUTH[to.matched[0].path]
    ) {
      return next("#auth")
    } else {
      return next()
    }
  } else {
    setTimeout(() => requireAuthentication(to, from, next), 250)
    return next()
  }
}

const routes: RouteConfig[] = [
  {
    name: "Account",
    path: `${MANAGED_PAGE_PREFIX}account`,
    component: () => import("@/pages/Account.vue"),
    beforeEnter: requireAuthentication
  },
  {
    name: "Account Details",
    path: `${MANAGED_PAGE_PREFIX}account/details`,
    component: () => import("@/pages/account/Details.vue"),
    beforeEnter: requireAuthentication
  },
  {
    name: "Account Purchases",
    path: `${MANAGED_PAGE_PREFIX}account/purchases`,
    component: () => import("@/pages/account/Purchases.vue"),
    beforeEnter: requireAuthentication
  },
  {
    name: "Product Details",
    path: `${MANAGED_PAGE_PREFIX}products/:id`,
    component: () => import("@/pages/PurchasedProduct.vue"),
    beforeEnter: requireAuthentication
  },
  {
    name: "Account Notifications",
    path: `${MANAGED_PAGE_PREFIX}account/notifications`,
    component: () => import("@/pages/account/Notifications.vue"),
    beforeEnter: requireAuthentication
  },
  {
    name: "Account Subscriptions",
    path: `${MANAGED_PAGE_PREFIX}account/subscriptions`,
    component: () => import("@/pages/account/Subscriptions.vue"),
    beforeEnter: requireAuthentication
  },
  {
    name: "Account Support",
    path: `${MANAGED_PAGE_PREFIX}account/support`,
    component: () => import("@/pages/account/Support.vue"),
    beforeEnter: requireAuthentication
  },
  {
    name: "Account Privacy",
    path: `${MANAGED_PAGE_PREFIX}account/privacy`,
    component: () => import("@/pages/account/Privacy.vue"),
    beforeEnter: requireAuthentication
  },
  {
    name: "New Message",
    path: `${MANAGED_PAGE_PREFIX}messages/new`,
    component: () => import("@/pages/MessageThread/New.vue")
    // beforeEnter: requireAuthentication // Causing issues not having route params available
  },
  {
    name: "Message Thread",
    path: `${MANAGED_PAGE_PREFIX}messages/:id`,
    component: () => import("@/pages/MessageThread/Show.vue")
    // beforeEnter: requireAuthentication // Causing issues not having route params available
  },
  {
    name: "Messages",
    path: `${MANAGED_PAGE_PREFIX}messages`,
    component: () => import("@/pages/MessageThread/Index.vue")
    // beforeEnter: requireAuthentication // Causing issues not having route params available
  },
  {
    name: "Message Participants",
    path: `${MANAGED_PAGE_PREFIX}messages/:id/participants`,
    component: () => import("@/pages/MessageThread/Participants.vue")
    // beforeEnter: requireAuthentication // Causing issues not having route params available
  },
  {
    name: "Schedule",
    path: "/me/schedule",
    component: () => import("@/pages/Schedule.vue")
  },
  {
    path: "/discover/programs",
    component: () => import("@/pages/Discover.vue")
  },
  {
    path: "/discover/events",
    component: () => import("@/pages/Discover.vue")
  },
  {
    path: "/discover/packs",
    component: () => import("@/pages/Discover.vue")
  },
  {
    name: "My Content",
    path: `${MANAGED_PAGE_PREFIX}my-content`,
    component: () => import("@/pages/MyContent.vue"),
    beforeEnter: requireAuthentication
  },
  {
    name: "My Submissions",
    path: `${MANAGED_PAGE_PREFIX}submissions/:formType?`,
    component: () => import("@/pages/submissions/SubmissionsNumber.vue"),
    beforeEnter: requireAuthentication
  },
  {
    name: "Form Submission",
    path: `${MANAGED_PAGE_PREFIX}forms/:formId/submissions/:id`,
    component: () => import("@/pages/submissions/FormSubmission.vue"),
    beforeEnter: requireAuthentication
  },
  {
    name: "Form Submissions",
    path: `${MANAGED_PAGE_PREFIX}forms/:formId/submissions`,
    component: () => import("@/pages/submissions/FormSubmissions.vue"),
    beforeEnter: requireAuthentication
  },
  {
    name: "Workout Sessions",
    path: `${MANAGED_PAGE_PREFIX}workout/:id/sessions`,
    component: () => import("@/pages/workout/Sessions.vue"),
    beforeEnter: requireAuthentication
  },
  {
    name: "Invoice",
    path: `${MANAGED_PAGE_PREFIX}invoices/:uuid`,
    component: () => import("@/pages/Invoice.vue")
  },
  {
    name: "Form",
    path: "/forms/:id",
    component: () => import("@/pages/Form.vue")
  },
  {
    name: "Buy",
    path: `${BUY_PATH}:ids`,
    component: () => import("@/pages/Buy.vue")
  },
  {
    name: "Unsubscribe",
    path: "/subscriber/:id/unsubscribe",
    component: () => import("@/pages/UnsubscribeFromEmail.vue")
  },

  // Redirects
  {
    path: "/discover",
    redirect: "/discover/programs"
  },

  // Account App Pages
  {
    path: "*",
    component: DynamicPage
  }
]

const MANAGED_PAGES_REQUIRING_AUTH = routes
  .filter(
    route => route.path.startsWith(MANAGED_PAGE_PREFIX) && !!route.beforeEnter
  )
  .reduce((acc, route: any): Record<string, any> => {
    acc[route.path] = route
    return acc
  }, {} as Record<string, any>)

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior
})

const pollForCurrentPage = (path: string, iteration = 0): Promise<any> => {
  return new Promise(resolve => {
    const currentPage = (store.state as any).pages.currentPage

    if (currentPage?.path === path) {
      resolve(currentPage)
      return
    }

    if (iteration < 20) {
      setTimeout(() => {
        pollForCurrentPage(path, iteration + 1).then(resolve)
      }, 100)
    } else {
      resolve(null) // Resolve with null if page not found after max iterations
    }
  })
}

let isInitialLoad = true

router.beforeEach(requireAuthentication)
router.afterEach((to, from) => {
  const toPath = to?.path

  if (toPath != from?.path || isInitialLoad) {
    isInitialLoad = false

    if (toPath.startsWith(MANAGED_PAGE_PREFIX)) {
      trackGTMEvent(GTM_EVENTS.PAGE_VIEW, {
        data: {
          title: to.name,
          content_type: "page",
          href: standardizeUrl(toPath)
        }
      })

      return
    }

    pollForCurrentPage(toPath).then(currentPage => {
      if (!currentPage) return

      trackGTMEvent(GTM_EVENTS.PAGE_VIEW, {
        data: {
          title: currentPage.title,
          content_type: currentPage.type,
          href: standardizeUrl(toPath)
        }
      })
    })
  }

  if (toPath?.startsWith(MANAGED_PAGE_PREFIX)) {
    // Wait for default transition to finish
    setTimeout(() => {
      window.dispatchEvent(
        new CustomEvent(MVT_EVENTS.ON_PAGE_CHANGE, {
          detail: standardizeUrl(toPath)
        })
      )
    }, 200)

    return
  }

  if (EventBus.hasListener(EVENTS.PAGE_BLOCKS_RENDERED)) return

  EventBus.$once(EVENTS.PAGE_BLOCKS_RENDERED, () => {
    window.dispatchEvent(
      new CustomEvent(MVT_EVENTS.ON_PAGE_CHANGE, {
        detail: standardizeUrl(toPath)
      })
    )
  })
})

export default router
