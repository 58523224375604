import { getQueryParam, getPathFromUrl } from "@/lib/url-helpers"

import router from "@/router"

import EventBus, { EVENTS } from "@/lib/event-bus"

export const handleNotificationUrl = (url: string) => {
  const blockActionQueryParam = getQueryParam(url, "block-action")

  if (blockActionQueryParam) {
    EventBus.$emit(EVENTS.TRIGGER_BLOCK_ACTION, blockActionQueryParam)
  } else {
    const path = getPathFromUrl(url, true)

    if (path !== "/") router.push(path)
  }
}
