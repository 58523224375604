import Cookies from "js-cookie"

import AUTH_PROVIDERS from "@shared/auth-providers.json"

import ApiClient from "@/lib/api-client"
import { AUTH_QUERY_PARAM } from "@/lib/constants"
import EventBus, { EVENTS } from "@/lib/event-bus"
import { getQueryParam } from "@/lib/url-helpers"
import { getUserTimezone } from "@/lib/date-utils"
import { DEFAULT, setLocale } from "@/store/i18n"
import { trackGTMEvent, GTM_EVENTS } from "@/lib/gtm-utils"

const SESSION_COOKIE_KEY = "session"

const getAuthSession = async () => {
  const authParam = getQueryParam(window.location.href, AUTH_QUERY_PARAM)

  if (authParam) return JSON.parse(atob(authParam))

  const sessionCookie = Cookies.get("session")

  return sessionCookie ? JSON.parse(sessionCookie) : null
}

const initializeAnalyticsSession = dispatch => {
  dispatch("analytics/initializeSession", {}, { root: true })
}

const handleSuccessfulAuth = (data, commit) => {
  commit("setAuthentication", {
    user: data.user,
    authentication_token: data.authentication_token,
    authenticated: true
  })
  commit("access/setAppSubscription", data.user.app_subscription, {
    root: true
  })
  commit("programs/setProgramSubscriptions", data.program_subscriptions, {
    root: true
  })
  commit("events/setEventSubscriptions", data.event_subscriptions, {
    root: true
  })
  commit("packs/setPackSubscriptions", data.pack_subscriptions, {
    root: true
  })
  commit("pages/setPageCompletions", data.page_completions.by_uuid, {
    root: true
  })
  commit("pages/setPageSubscriptions", data.page_subscriptions, {
    root: true
  })
  commit("pages/setBookmarkedPages", data.bookmarked_pages, {
    root: true
  })
  commit("products/setPastDueSubscriptions", data.past_due_subscriptions, {
    root: true
  })
  commit(
    "subscriberProperties/setSubmittedProperties",
    data.subscriber_properties,
    {
      root: true
    }
  )
  commit("subscriber/setBlockedUserIds", data.blocked_user_ids, {
    root: true
  })

  data.exercise_swaps.forEach(swap => {
    commit(
      "subscriber/setExerciseSwap",
      {
        exerciseId: swap.exercise_id,
        alternateId: swap.alternate_id
      },
      {
        root: true
      }
    )
  })

  if (data.user.language && data.user.language !== DEFAULT) {
    setLocale(data.user.language)
  }

  window.MVT_memberData = {
    id: data.user.subscriber_uuid,
    name: data.user.name,
    completions: data.page_completions.by_content_type
  }

  EventBus.$emit(EVENTS.AUTH_COMPLETED)
}

export default {
  namespaced: true,

  state: {
    hasCheckedAuthentication: false,
    authenticated: false,
    authenticationToken: null,
    user: {}
  },

  mutations: {
    setAuthentication(state, { authentication_token, user, authenticated }) {
      if (authenticated) {
        if (user) {
          const {
            email,
            id,
            name,
            timezone,
            subscriber_id,
            subscriber_uuid,
            is_admin,
            is_account_admin,
            is_app_messaging_profile,
            active_product_ids,
            global_events_channel,
            avatar_urls,
            can_receive_messages_from_members,
            language,
            passwordless,
            auth_provider,
            attributes
          } = user

          state.user = {
            email,
            id,
            name,
            timezone,
            subscriberId: subscriber_id,
            subscriberUuid: subscriber_uuid,
            isAdmin: is_admin,
            isAccountAdmin: is_account_admin,
            isAppMessagingProfile: is_app_messaging_profile,
            activeProductIds: active_product_ids,
            globalEventsChannel: global_events_channel,
            avatarUrls: avatar_urls,
            canReceiveMessagesFromMembers: can_receive_messages_from_members,
            language,
            passwordless,
            auth_provider,
            attributes
          }
        }

        if (authentication_token) {
          const authPayload = {
            token: authentication_token,
            email: user.email
          }

          state.authenticationToken = authentication_token
          Cookies.set(SESSION_COOKIE_KEY, JSON.stringify(authPayload), {
            sameSite: "none",
            secure: true,
            expires: 365
          })
        }
      } else {
        state.user = {}
        state.authenticationToken = null
        Cookies.remove(SESSION_COOKIE_KEY)
      }

      state.authenticated = authenticated
      state.hasCheckedAuthentication = true
    },

    setAuthenticationChecked(state, hasChecked = true) {
      state.hasCheckedAuthentication = hasChecked
    },

    setUser(state, data) {
      state.user = { ...state.user, ...data }
    }
  },

  actions: {
    async initialize({ commit, dispatch }) {
      const session = await getAuthSession()

      if (!session) {
        initializeAnalyticsSession(dispatch)
        commit("setAuthenticationChecked")
        return
      }

      const { token, email } = session

      return ApiClient.post(
        "/auth/verify",
        {},
        {
          headers: {
            "x-user-token": token,
            "x-user-email": email
          }
        }
      )
        .then(({ data }) => {
          handleSuccessfulAuth(data, commit)
        })
        .catch(() => {
          commit("setAuthentication", { authenticated: false })
        })
        .finally(() => initializeAnalyticsSession(dispatch))
    },

    login({ commit }, { email, password, provider, providerId }) {
      return ApiClient.post("/auth/login", {
        email,
        password,
        provider,
        provider_id: providerId
      })
        .then(({ data }) => {
          handleSuccessfulAuth(data, commit)

          trackGTMEvent(GTM_EVENTS.LOGIN, {
            user: { id: data.user.subscriber_uuid }
          })

          return data.user
        })
        .catch(res => {
          throw res.response.data
        })
    },

    fetchUserAuth(_, { email }) {
      return ApiClient.post("/auth/fetch", { email }).then(res => res.data)
    },

    signUp(
      { commit },
      { email, name, password, provider = null, providerId = null }
    ) {
      return ApiClient.post("/auth/sign_up", {
        email,
        name,
        password,
        provider,
        provider_id: providerId,
        timezone: getUserTimezone()
      })
        .then(({ data }) => {
          handleSuccessfulAuth(data, commit)

          trackGTMEvent(GTM_EVENTS.SIGN_UP, {
            user: { id: data.user.subscriber_uuid }
          })

          return data.user
        })
        .catch(res => {
          throw res.response.data
        })
    },

    fetchCurrentProvider(_, { email, providerId, provider }) {
      return ApiClient.get("/auth/provider", {
        params: {
          email,
          provider_id: providerId,
          provider
        }
      }).then(res => res.data.current_provider)
    },

    linkProvider(_, { providerId, provider, email = null }) {
      return ApiClient.put("/auth/provider", {
        email,
        provider,
        provider_id: providerId
      }).then(res => res.data.current_provider)
    },

    setPassword({ commit }, { password, name = null }) {
      return ApiClient.post("/auth/set_password", { password, name })
        .then(data => {
          if (name && name.length) {
            commit("setUser", { name })
          }

          return data
        })
        .catch(res => {
          throw res.response.data
        })
    },

    addPurchasedProduct({ commit, getters }, productId) {
      commit("setUser", {
        activeProductIds: (getters.currentUser.activeProductIds || []).concat(
          productId
        )
      })
    },

    update({ commit }, { email, name, timezone }) {
      return ApiClient.put("/auth", { email, name, timezone })
        .then(({ data }) => {
          commit("setAuthentication", { ...data, authenticated: true })
          return data.user
        })
        .catch(err => {
          throw err.response.data
        })
    },

    updateAvatar({ commit }, { avatar }) {
      return ApiClient.put("/auth/update_avatar", { avatar }).then(res => {
        commit("setUser", { avatarUrls: res.data.avatar_urls })
      })
    },

    logout({ commit }) {
      return ApiClient.post("/auth/logout")
        .catch(() => {})
        .finally(() => {
          commit("setAuthentication", { authenticated: false })
          commit("products/setPastDueSubscriptions", [], { root: true })
          commit("messages/reset", {}, { root: true })
          commit("subscriber/reset", {}, { root: true })

          setLocale("en")
        })
    },

    requestPasswordlessAuth(_, { email }) {
      return ApiClient.post("/auth/passwordless/request", { email }).catch(
        err => {
          throw err.response.data
        }
      )
    },

    verifySubscriber({ commit }, { email, name = null }) {
      return ApiClient.post("/auth/verify_subscriber", { email, name })
        .then(({ data }) => {
          handleSuccessfulAuth(data, commit)

          return data.user
        })
        .catch(err => {
          throw err.response.data
        })
    },

    verifyPasswordlessAuth({ commit }, { token }) {
      return ApiClient.post("/auth/passwordless/verify", {
        token
      })
        .then(({ data }) => {
          handleSuccessfulAuth(data, commit)

          return data.user
        })
        .catch(err => {
          throw err.response.data
        })
    },

    requestResetPassword(_, { email }) {
      return ApiClient.get("/auth/reset_password", { params: { email } }).catch(
        err => {
          throw err.response.data
        }
      )
    },

    resetPassword({ commit }, { token, password, passwordConfirmation }) {
      return ApiClient.post("/auth/reset_password", {
        token,
        password,
        password_confirmation: passwordConfirmation
      })
        .then(({ data }) => handleSuccessfulAuth(data, commit))
        .catch(err => {
          throw err.response.data
        })
    },

    fetchAccountPurchases() {
      return ApiClient.get("/purchases").then(res => res.data)
    },

    fetchAccountSubscriptions() {
      return ApiClient.get("/subscriptions").then(res => res.data)
    },

    cancelAccountSubscription(_, id) {
      return ApiClient.delete(`/subscriptions/${id}`).then(res => res.data)
    },

    fetchBlockAction(_, id) {
      return ApiClient.get(`/block_actions/${id}`).then(res => res.data)
    }
  },

  getters: {
    isAdmin(_, getters) {
      return !!getters.isAuthenticated && !!getters.currentUser.isAdmin
    },

    isAccountAdmin(_, getters) {
      return !!getters.isAuthenticated && !!getters.currentUser.isAccountAdmin
    },

    isAppMessagingProfile(_, getters) {
      return !!getters.currentUser.isAppMessagingProfile
    },

    canMessageAll(_, getters) {
      return getters.isAccountAdmin || getters.isAppMessagingProfile
    },

    isGuest(state) {
      return state.hasCheckedAuthentication && !state.authenticated
    },

    isAuthenticated(state) {
      return state.hasCheckedAuthentication && state.authenticated
    },

    hasCheckedAuthentication(state) {
      return state.hasCheckedAuthentication
    },

    currentUser(state, getters) {
      return getters.isAuthenticated ? state.user : {}
    },

    subscriberAttributes(_, getters) {
      return getters.currentUser.attributes || {}
    },

    userShouldFinishSetup(_, getters) {
      return (
        getters.isAuthenticated &&
        getters.currentUser.passwordless &&
        getters.currentUser.auth_provider === AUTH_PROVIDERS.passwordless
      )
    },

    authenticationToken(state, getters) {
      return getters.isAuthenticated ? state.authenticationToken : {}
    },

    hasPurchasedProduct(state) {
      return productIdOrIds => {
        const productIds = Array.isArray(productIdOrIds)
          ? productIdOrIds
          : [productIdOrIds]

        return !!(state.user?.activeProductIds || []).some(id =>
          productIds.includes(id)
        )
      }
    },

    providers(_, __, state) {
      let providers = Object.values(AUTH_PROVIDERS)

      if (!state.global.access.sso_apple_enabled) {
        providers = providers.filter(p => p !== AUTH_PROVIDERS.apple)
      }

      if (!state.global.access.sso_google_enabled) {
        providers = providers.filter(p => p !== AUTH_PROVIDERS.google)
      }

      return providers
    }
  }
}
